<template>
  <div class="fb-new">
    <div class="info clearFix">
      <div
        class="main fr"
        :class="{
          wait: orderData.orderStatus == 0 || orderData.orderStatus == 1,
          work: orderData.orderStatus == 2,
          finish: orderData.orderStatus == 3
        }"
      >
        <p class="title">卡号：{{ orderData.virtualId || orderData.card }}</p>
        <span class="desc"
          >反馈类型：{{ typeAction(orderData.questionType) }}</span
        ><br />
      </div>
    </div>
    <div class="progress" id="progress">
      <ul class="clearFix" id="smsList">
        <template v-for="item in messageList">
          <li class="user fl" v-if="item.msgType == 1" :key="item">
            <span class="time">我&emsp;{{ item.createTime }}</span>
            <span class="text">{{ item.content }}</span
            ><br />
            <img
              v-for="(img, index) in item.imgList"
              :key="index"
              :src="img"
              alt=""
              @click="seeImgFn(img)"
            />
          </li>
          <li class="answer fr" v-if="item.msgType == 0" :key="item">
            <span class="time"
              >{{ item.createTime }}&emsp;客服{{ item.sendFrom }}</span
            >
            <span class="text">{{ item.content }}</span
            ><br />
            <img
              v-for="(img, index) in item.imgList"
              :key="index"
              :src="img"
              alt=""
              @click="seeImgFn(img)"
            />
          </li>
        </template>
        <li class="user fl" v-if="Boolean(question) || imgList.length > 0">
          <span class="time">我</span>
          <span class="test">{{ question }}</span
          ><br />
          <img
            @click="seeImgFn(item, index + 1)"
            :src="item"
            :key="index"
            alt=""
            v-for="(item, index) in imgList"
          />
          <br /><span class="tips" v-if="Boolean(fileList.length > 0)"
            >tips:图片需随问题描述一起发送</span
          >
        </li>
      </ul>
    </div>
    <div class="btns">
      <div class="noSubmit" v-if="orderData.reply == 1">当前工单会话已结束</div>
      <input
        type="file"
        class="img"
        @change="uploadImg($event)"
        :disabled="Boolean(fileList.length >= 3)"
      />
      <input
        type="text"
        maxlength="150"
        v-model="question"
        @focus="focus"
        @input="focus"
        class="question"
        placeholder="输入您的问题描述并发送"
      />
      <button class="btn" :disabled="!Boolean(question)" @click="sendMsg">
        发送
      </button>
    </div>
    <!-- 预览图片 -->
    <van-popup v-model:show="seeImgShow">
      <div class="previewBox">
        <div class="previewImg">
          <img :src="seeImg" alt="logo" />
        </div>
        <div
          class="previewButton"
          @click="deleteImg"
          v-if="Boolean(seeImgIndex)"
        >
          删除
        </div>
      </div>
    </van-popup>
    <div class="questionBtn" @click="moreQuestion">
      <span>常见<br />问题</span>
    </div>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import {
  toRefs,
  reactive,
  onMounted,
  onUnmounted,
  getCurrentInstance
} from "vue";
import { useRoute } from "vue-router";
import { getWordOrderDetail, chatMsg } from "_API_/api.services";
import { fileToImgFn } from "@/utils/utils";
import { getStore } from "@/utils/store";
export default {
  setup() {
    const state = reactive({
      question: "", //问题描述
      imgList: [], //图片列表
      fileList: [], //图片路径列表
      seeImg: "", //预览的图片
      seeImgIndex: "", //预览的图片下标
      seeImgShow: false, //预览的图片弹框展示
      orderId: "", //订单id
      messageList: [], //消息列表
      orderData: {}, //工单数据
      timeId: 0, //定时器id
      typeList: [] //类型列表
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //图片上传限制
      upImgFn() {
        if (state.fileList.length >= 3) {
          tool.toast(
            {
              msg: "最多只支持上传3张图片！"
            },
            () => {}
          );
        }
      },
      //时间轮询调用接口
      timeFn() {
        clearInterval(state.timeId);
        state.timeId = setInterval(() => {
          ctx.getOrderData(true);
        }, 10000);
      },
      //获取聊天视口高度
      focus() {
        var list = document.getElementById("progress");
        list.scrollTop = list.scrollHeight;
      },
      //上传图片
      uploadImg(event, file) {
        var file = event.target.files[0];
        if (!Boolean(file)) {
          return;
        }
        if (file.type != "image/png" && file.type != "image/jpeg") {
          tool.toast(
            {
              msg: "只支持上传png和jpeg图片！"
            },
            () => {}
          );
          return;
        }
        if (file.size / 1024 > 2048) {
          tool.toast(
            {
              msg: "图片最大不得超过2MB"
            },
            () => {}
          );
          return;
        }
        state.fileList.push(file);
        ctx.fileToImg(file);
        ctx.upImgFn();
        event.target.value = "";
      },
      async fileToImg(file) {
        let imgUrl = await fileToImgFn(file); // 图片转换处理,图片的 base64 格式, 可以直接当成 img 的 src 属性值
        state.imgList.push(imgUrl);
      },
      // 预览图片
      seeImgFn(item, index = 0) {
        state.seeImgShow = true;
        state.seeImg = item;
        state.seeImgIndex = index;
      },
      // 删除图片
      deleteImg() {
        state.imgList.splice(state.seeImgIndex - 1, 1);
        state.fileList.splice(state.seeImgIndex - 1, 1);
        state.seeImgShow = false;
      },
      // 获取工单数据
      getOrderData(noLoading = false) {
        getWordOrderDetail(
          {
            woId: state.orderId
          },
          {
            noLoading
          }
        ).then(({ data: res }) => {
          if (res.code == 0) {
            state.orderData = res.data;
            state.messageList = ctx.formatterMsgList(res.data); //格式化消息列表
            setTimeout(() => {
              ctx.focus();
            }, 100);
            return;
          }
          tool.toast(
            {
              msg: res.msg
            },
            () => {}
          );
        });
      },
      //发送消息
      sendMsg() {
        ctx.timeFn();
        let conf = new FormData(); //创建一个新的 FormData 对象。
        //向 FormData 中添加新的属性值
        conf.append("content", state.question);
        conf.append("woId", state.orderId);
        state.fileList.forEach(item => {
          conf.append("files", item);
        });
        chatMsg(conf).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "消息发送成功，静候客服处理"
              },
              () => {}
            );
            ctx.getOrderData(true);
            state.question = "";
            state.imgList = [];
            state.fileList = [];
            return;
          }
          tool.toast(
            {
              msg: res.msg
            },
            () => {}
          );
        });
      },
      //格式化消息列表
      formatterMsgList(data) {
        let msgType = 1; // 0 客服消息   1 用户消息
        let content = data.description;
        let imgList = data.images;
        let createTime = data.createTime;
        //拼凑第一条消息
        let firstMsg = {
          content,
          createTime,
          msgType,
          imgList: [],
          sendFrom: "",
          sendTo: ""
        };
        imgList.forEach(item => {
          firstMsg.imgList.push(item.url);
        });
        //格式化消息记录
        let msgList = [];
        data.messages.forEach(item => {
          item.imgList = Boolean(item.imageUrl) ? item.imageUrl.split(",") : [];
          msgList.push(item);
        });
        msgList.unshift(firstMsg);
        return msgList;
      },
      // 反馈类型
      typeAction(type) {
        if (!type) {
          return;
        }
        let data = state.typeList.filter(item => {
          return item.id == type;
        })[0];
        return data.title;
      },
      //中间悬浮按钮=>常见问题
      moreQuestion() {
        router.push({
          name: "fb-type",
          query: {
            hide: 1
          }
        });
      }
    };
    onMounted(() => {
      //获取路由携带的参数
      state.orderId = route.query.orderId;
      //获取类型列表信息
      state.typeList = getStore({ name: "typeList" });
      ctx.timeFn();
      ctx.getOrderData();
    });
    onUnmounted(() => {
      clearInterval(state.timeId);
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.fb-new {
  background: #f3eeee;
  .clearFix:after {
    display: block;
    content: "";
    zoom: 1;
    clear: both;
  }
  .info {
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 2px 2px #f2f2f2;
    background: #fff;
    padding: 10px;
    height: 120px;

    .main {
      width: 80%;
      position: relative;

      p.title {
        height: 60px;
        line-height: 60px;
        font-size: 30px;
        font-weight: bold;
      }
      .desc {
        font-size: 22px;
      }
    }

    .main:before {
      width: 110px;
      height: 110px;
      border-radius: 10px;
      color: #fff;
      display: block;
      position: absolute;
      top: 4px;
      right: 600px;
      line-height: 110px;
      text-align: center;
      font-size: 20px;
    }

    .main.finish:before {
      content: "已解决";
      background: lightgreen;
    }

    .main.wait:before {
      content: "待解决";
      background: deepskyblue;
    }

    .main.work:before {
      content: "解决中";
      background: #ef4f4f;
    }
    .fr {
      float: right;
    }
  }

  .progress {
    // height: calc(100vh - 550px);
    height: 1030px;
    overflow: scroll;
    padding: 20px;

    li {
      background: #fff;
      line-height: 60px;
      font-size: 30px;
      // letter-spacing: 10px;
      color: #3e3e3e;
      word-break: normal;
      white-space: pre-wrap;
      word-wrap: break-word;
      padding: 20px;
      margin: 60px 0px;
      position: relative;
      font-family: "微软雅黑";

      textarea {
        height: 30px;
      }

      .time {
        position: absolute;
        font-size: 16px;
        color: #c9c9c9;
        right: 0;
        top: -54px;
      }
      img {
        max-width: 120px;
        margin: 10px 20px;
      }
      .tips {
        font-size: 10px;
        color: #c0c0c0;
      }
    }

    li.user {
      border-radius: 20px 0 20px 20px;
      background: #77c92d26;
      border: 1px solid #77c92d80;
      width: 600px;
    }

    li.answer {
      border-radius: 0 20px 20px 20px;
      border: 1px solid #26a2fb66;
      background: #26a2fb24;
      width: 580px;
      margin-left: 86px;
      .time {
        left: 0;
      }
    }

    li:before {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      color: #fff;
      display: block;
      position: absolute;
      line-height: 30px;
      text-align: center;
      font-size: 30px;
      top: -30px;
    }

    li.user:before {
      content: "";
      background: url(../../assets/image/feedBack/user1.png);
      background-size: 100%;
      right: -75px;
    }

    li.answer:before {
      content: "";
      background: url(../../assets/image/feedBack/kefu.png) no-repeat center;
      background-size: 80%;
      left: -90px;
      border: 5px solid #0094fe;
    }
  }

  .btns {
    height: 70px;
    line-height: 70px;
    background: #fff;
    text-align: center;
    padding: 10px;
    margin-top: 30px;
    position: relative;
    input.img {
      width: 80px;
      height: 80px;
      margin-right: 16px;
      position: relative;
      vertical-align: middle;
      overflow: hidden;
    }
    input.img:before {
      content: "";
      width: 80px;
      height: 80px;
      display: inline-block;
      vertical-align: middle;
      background: url(../../assets/image/feedBack/uploadImg.png);
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-color: #fff;
      border: 2px solid #fff;
    }
    input.question {
      width: 67%;
      height: 60px;
      text-align: left;
      vertical-align: middle;
      font-size: 30px;
    }
    button {
      width: 120px;
      height: 70px;
      @include background_color("background_color1");
      border-radius: 20px;
      border: none;
      color: #fff;
      vertical-align: middle;
      margin-left: 12px;
    }
    button[disabled] {
      @include background_color("background_color2");
    }
    .noSubmit {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      @include background_color("background_color1");
      z-index: 5;
      font-size: 30px;
      line-height: 88px;
      color: #fff;
    }
  }
}
.pop {
  text-align: center;
  .img {
    max-width: 90%;
    max-height: 60px;
    margin-bottom: 20px;
  }
}

.previewBox {
  display: flex;
  flex-direction: column;
  .previewImg {
    img {
      width: 268px;
    }
  }
  .previewButton {
    text-align: center;
    color: #ffffff;
    background: #ff4937;
  }
}

.questionBtn {
  width: 80px;
  height: 80px;
  line-height: 80px;
  padding: 10px;
  color: #fff;
  @include background_color("background_color1");
  border-radius: 50%;
  position: fixed;
  right: 1%;
  top: 40%;
  text-align: center;
  word-break: break-all;
  box-shadow: 2px 2px 2px 2px #ccc;
  span {
    display: inline-block;
    line-height: 30px;
    vertical-align: middle;
    font-size: 20px;
  }
}
</style>
